import React from 'react'

export default function EquipmentsIcon(props) {
  return (
    <svg  className={props.classx} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.6 20H14.4C18.4 20 20 18.4 20 14.4V9.6C20 5.6 18.4 4 14.4 4H9.6C5.6 4 4 5.6 4 9.6V14.4C4 18.4 5.6 20 9.6 20Z" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5 17H13.5C16 17 17 16 17 13.5V10.5C17 8 16 7 13.5 7H10.5C8 7 7 8 7 10.5V13.5C7 16 8 17 10.5 17Z" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.01001 4V2" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 4V2" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 4V2" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 8H22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 12H22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 16H22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 20V22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.01 20V22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.01001 20V22" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 8H4" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12H4" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 16H4" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9.69995L11.06 11.34C10.85 11.7 11.02 12 11.44 12H12.56C12.98 12 13.15 12.3 12.94 12.66L12 14.3" xstroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
