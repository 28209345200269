const BidStatuses = [
    {
        id:1,
        status: "Submitted and Pending Approval"
    },
    {
        id:2,
        status: "Won"
    },
    {
        id:3,
        status: "Lost"
    },
    {
        id:4,
        status: "Cancelled"
    }

]
// eslint-disable-next-line import/no-anonymous-default-export
export default {BidStatuses};