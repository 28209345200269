
import React from 'react'

export default function AddFileIcon(props) {
  return (
    <svg className={props.classx} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99945 17.75C8.58945 17.75 8.24945 17.41 8.24945 17V12.81L7.52945 13.53C7.23945 13.82 6.75945 13.82 6.46945 13.53C6.17945 13.24 6.17945 12.76 6.46945 12.47L8.46945 10.47C8.67945 10.26 9.00945 10.19 9.28945 10.31C9.56945 10.42 9.74945 10.7 9.74945 11V17C9.74945 17.41 9.40945 17.75 8.99945 17.75Z" xfill="#292D32"/>
        <path d="M10.9995 13.7499C10.8095 13.7499 10.6195 13.6799 10.4695 13.5299L8.46945 11.5299C8.17945 11.2399 8.17945 10.7599 8.46945 10.4699C8.75945 10.1799 9.23945 10.1799 9.52945 10.4699L11.5295 12.4699C11.8195 12.7599 11.8195 13.2399 11.5295 13.5299C11.3795 13.6799 11.1895 13.7499 10.9995 13.7499Z" xfill="#292D32"/>
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" xfill="#292D32"/>
        <path d="M22 10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V1.99999C13.25 1.69999 13.43 1.41999 13.71 1.30999C13.99 1.18999 14.31 1.25999 14.53 1.46999L22.53 9.46999C22.74 9.67999 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.80999V5.99999C14.75 8.57999 15.42 9.24999 18 9.24999H20.19L14.75 3.80999Z" xfill="#292D32"/>
    </svg>
  )
}
