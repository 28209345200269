const expenses= [{"id":1,"amount":"$276017.16","date":"07-06-2011","category":"Electricity","authorized_by":"Cristin Brauner", "expense_description": "Repair of Grinding and Shreding Equipment22","product_id":688833},
{"id":2,"amount":"$403352.62","date":"11-07-2006","category":"Welfare","authorized_by":"Marna Brimmacombe", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":733487},
{"id":3,"amount":"$789203.01","date":"25-09-2014","category":"Electricity","authorized_by":"Fonsie Abramowsky", "expense_description": "Repair of Grinding and Shreding Equipment 22","product_id":375106},
{"id":4,"amount":"$334008.89","date":"02-04-2016","category":"Flight","authorized_by":"Dannye Rookledge", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":553766},
{"id":5,"amount":"$614819.76","date":"25-09-2016","category":"Transportation","authorized_by":"Rowen Sedman", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":646091},
{"id":6,"amount":"$570429.36","date":"27-06-2014","category":"Flight","authorized_by":"Janet Suerz", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":155888},
{"id":7,"amount":"$264469.89","date":"05-09-2010","category":"Health","authorized_by":"Drucy Maken", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":620494},
{"id":8,"amount":"$332642.43","date":"15-05-2018","category":"Project","authorized_by":"Josselyn Willans", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":211833},
{"id":9,"amount":"$262779.35","date":"26-08-2015","category":"Project","authorized_by":"Lewes Lancastle", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":642497},
{"id":10,"amount":"$362537.11","date":"25-03-2012","category":"Health","authorized_by":"Aubine Hedlestone", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":842279},
{"id":11,"amount":"$360829.58","date":"04-01-2015","category":"Transportation","authorized_by":"Addie Aronovitz", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":477536},
{"id":12,"amount":"$845941.10","date":"27-04-2021","category":"Electricity","authorized_by":"Tobiah Mulligan", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":714595},
{"id":13,"amount":"$490480.40","date":"01-10-2011","category":"Stationary","authorized_by":"Sunny Arrol", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":466359},
{"id":14,"amount":"$841993.82","date":"25-05-2007","category":"Welfare","authorized_by":"Dorie Pachmann", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":987045},
{"id":15,"amount":"$979695.51","date":"26-06-2021","category":"Project","authorized_by":"Clemmy Labrow", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":468353},
{"id":16,"amount":"$800760.61","date":"04-08-2014","category":"Welfare","authorized_by":"Chilton Richardt", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":948879},
{"id":17,"amount":"$518777.58","date":"21-06-2020","category":"Flight","authorized_by":"Ingrid Inchboard", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":389088},
{"id":18,"amount":"$470187.73","date":"09-01-2008","category":"Internet","authorized_by":"Dud Smith", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":966465},
{"id":19,"amount":"$806087.15","date":"06-09-2020","category":"Internet","authorized_by":"Riley Charopen", "expense_description": "Repair of Grinding and Shreding Equipment22","product_id":603913},
{"id":20,"amount":"$63959.13","date":"29-08-2009","category":"Internet","authorized_by":"Josiah Libermore", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":476586},
{"id":21,"amount":"$773307.96","date":"23-05-2007","category":"Flight","authorized_by":"Heall Midgley", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":283074},
{"id":22,"amount":"$284427.81","date":"17-05-2005","category":"Internet","authorized_by":"Donna McCreadie", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":334678},
{"id":23,"amount":"$508620.12","date":"02-10-2005","category":"Flight","authorized_by":"Hilarius Hadden", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":246875},
{"id":24,"amount":"$601016.30","date":"16-07-2020","category":"Flight","authorized_by":"Adrianne Shurville", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":131201},
{"id":25,"amount":"$229532.82","date":"04-10-2007","category":"Health","authorized_by":"Cleavland Boyland", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":296150},
{"id":26,"amount":"$826700.74","date":"09-02-2006","category":"Health","authorized_by":"Fifine Logsdail", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":770596},
{"id":27,"amount":"$337784.11","date":"26-08-2008","category":"Flight","authorized_by":"Fidel Wraighte", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":986835},
{"id":28,"amount":"$728132.47","date":"03-07-2011","category":"Flight","authorized_by":"Winnie Goodhew", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":909706},
{"id":29,"amount":"$445853.29","date":"28-12-2011","category":"Stationary","authorized_by":"Steven Souch", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":935853},
{"id":30,"amount":"$576890.19","date":"01-08-2014","category":"Welfare","authorized_by":"Ingaberg Shuttell", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":315372},
{"id":31,"amount":"$51439.32","date":"09-05-2012","category":"Health","authorized_by":"Kort Rehme", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":101006},
{"id":32,"amount":"$929796.74","date":"12-08-2015","category":"Health","authorized_by":"Danya Eddisford", "expense_description": "Repair of Grinding and Shreding Equipmentxx","product_id":708250},
{"id":33,"amount":"$709415.38","date":"14-06-2018","category":"Health","authorized_by":"Leta Mulliner", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":738205},
{"id":34,"amount":"$529956.27","date":"27-08-2006","category":"Flight","authorized_by":"Kerrill Heffernan", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":879261},
{"id":35,"amount":"$179302.06","date":"05-11-2009","category":"Health","authorized_by":"Clarisse Furbank", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":296139},
{"id":36,"amount":"$113009.66","date":"07-10-2012","category":"Internet","authorized_by":"Lacee Mauditt", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":543117},
{"id":37,"amount":"$147100.17","date":"12-03-2010","category":"Flight","authorized_by":"Mose Sandars", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":780851},
{"id":38,"amount":"$331510.17","date":"15-01-2006","category":"Electricity","authorized_by":"Merola Woolway", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":975140},
{"id":39,"amount":"$703141.74","date":"21-03-2022","category":"Electricity","authorized_by":"Kacy Beeson", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":891498},
{"id":40,"amount":"$84952.28","date":"03-08-2007","category":"Internet","authorized_by":"Marion Strotone", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":191486},
{"id":41,"amount":"$547969.39","date":"14-09-2015","category":"Stationary","authorized_by":"Deck Delia", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":724785},
{"id":42,"amount":"$714066.18","date":"13-02-2020","category":"Internet","authorized_by":"Joy Froggatt", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":668701},
{"id":43,"amount":"$141152.59","date":"26-11-2017","category":"Transportation","authorized_by":"Beryl Jennery", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":684182},
{"id":44,"amount":"$532745.30","date":"10-01-2009","category":"Stationary","authorized_by":"Bourke Jeffery", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":391022},
{"id":45,"amount":"$756112.44","date":"01-02-2021","category":"Welfare","authorized_by":"Lonni Gianinotti", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":543314},
{"id":46,"amount":"$118452.17","date":"21-08-2007","category":"Internet","authorized_by":"Candie Sandercock", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":312265},
{"id":47,"amount":"$229539.41","date":"29-05-2008","category":"Electricity","authorized_by":"Darelle Wenman", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":269660},
{"id":48,"amount":"$149514.87","date":"26-06-2011","category":"Transportation","authorized_by":"Jephthah Bows", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":809729},
{"id":49,"amount":"$611585.85","date":"02-08-2014","category":"Transportation","authorized_by":"Stella Gannon", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":111193},
{"id":50,"amount":"$163743.64","date":"02-10-2007","category":"Welfare","authorized_by":"Manuel Leeson", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":182423},
{"id":51,"amount":"$590425.66","date":"20-03-2015","category":"Health","authorized_by":"Rudolf Buxy", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":885207},
{"id":52,"amount":"$267585.33","date":"25-03-2006","category":"Flight","authorized_by":"Delinda Ogbourne", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":730671},
{"id":53,"amount":"$305412.81","date":"13-08-2008","category":"Stationary","authorized_by":"Hy Lawty", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":515747},
{"id":54,"amount":"$191035.25","date":"24-01-2013","category":"Welfare","authorized_by":"Kati Hobson", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":988851},
{"id":55,"amount":"$76945.74","date":"27-05-2020","category":"Welfare","authorized_by":"Edyth Daen", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":123716},
{"id":56,"amount":"$241262.80","date":"09-05-2017","category":"Internet","authorized_by":"Dulce Anetts", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":145582},
{"id":57,"amount":"$327953.45","date":"20-01-2009","category":"Internet","authorized_by":"Liliane East", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":616348},
{"id":58,"amount":"$460863.79","date":"24-01-2011","category":"Project","authorized_by":"Kermie Djorvic", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":213436},
{"id":59,"amount":"$746988.86","date":"24-06-2012","category":"Health","authorized_by":"Laird Shewen", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":438635},
{"id":60,"amount":"$191018.21","date":"25-06-2013","category":"Transportation","authorized_by":"Marna Biggans", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":197572},
{"id":61,"amount":"$664682.88","date":"13-07-2007","category":"Transportation","authorized_by":"Alfie Hesser", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":157770},
{"id":62,"amount":"$973585.13","date":"16-05-2021","category":"Project","authorized_by":"Karl Thomasset", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":613702},
{"id":63,"amount":"$611265.42","date":"03-09-2005","category":"Internet","authorized_by":"Porter Goldstone", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":258144},
{"id":64,"amount":"$456569.69","date":"19-09-2009","category":"Welfare","authorized_by":"Ilise Dinwoodie", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":873170},
{"id":65,"amount":"$792020.83","date":"18-12-2007","category":"Health","authorized_by":"Dukie Whitney", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":533276},
{"id":66,"amount":"$129275.75","date":"18-01-2009","category":"Flight","authorized_by":"Kevin Longcake", "expense_description": "Repair of Grinding and Shreding Equipmentxxx","product_id":599355},
{"id":67,"amount":"$266528.62","date":"04-04-2017","category":"Electricity","authorized_by":"Filippo Cay", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":788080},
{"id":68,"amount":"$911417.28","date":"16-09-2015","category":"Project","authorized_by":"Bogart Stetson", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":502867},
{"id":69,"amount":"$820707.24","date":"17-08-2011","category":"Welfare","authorized_by":"Kitti Cleugh", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":216544},
{"id":70,"amount":"$194701.92","date":"29-10-2012","category":"Internet","authorized_by":"Fields Hindge", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":804679},
{"id":71,"amount":"$475309.53","date":"12-05-2013","category":"Electricity","authorized_by":"Ardyce Burress", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":736338},
{"id":72,"amount":"$200924.50","date":"01-04-2015","category":"Health","authorized_by":"Coretta Ebertz", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":450987},
{"id":73,"amount":"$847547.46","date":"29-05-2018","category":"Health","authorized_by":"Bernadette Bickerstasse", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":962502},
{"id":74,"amount":"$213854.78","date":"29-12-2009","category":"Internet","authorized_by":"Burtie Sawood", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":901740},
{"id":75,"amount":"$306741.27","date":"14-09-2008","category":"Electricity","authorized_by":"Addison Avrahamov", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":261956},
{"id":76,"amount":"$646659.28","date":"03-03-2019","category":"Electricity","authorized_by":"Luke Le Barr", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":682750},
{"id":77,"amount":"$649151.76","date":"20-02-2016","category":"Stationary","authorized_by":"Jemima Connew", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":413481},
{"id":78,"amount":"$123642.95","date":"14-11-2006","category":"Health","authorized_by":"Rodrick Shimmans", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":975947},
{"id":79,"amount":"$885937.05","date":"24-10-2019","category":"Health","authorized_by":"Silvester Carruthers", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":261275},
{"id":80,"amount":"$342889.18","date":"11-07-2011","category":"Welfare","authorized_by":"Vilma Wyld", "expense_description": "Repair of Grinding and Shreding Equipment test","product_id":204451},
{"id":81,"amount":"$569209.37","date":"11-05-2017","category":"Health","authorized_by":"Laurel Winfred", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":614643},
{"id":82,"amount":"$316414.09","date":"14-11-2010","category":"Electricity","authorized_by":"Madelle Elliott", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":181362},
{"id":83,"amount":"$918479.20","date":"16-10-2019","category":"Internet","authorized_by":"Querida Saleway", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":561699},
{"id":84,"amount":"$665785.55","date":"31-10-2020","category":"Flight","authorized_by":"Marlene Haswall", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":284493},
{"id":85,"amount":"$73919.01","date":"08-03-2012","category":"Flight","authorized_by":"Angy Kirsche", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":315921},
{"id":86,"amount":"$265905.68","date":"20-06-2006","category":"Welfare","authorized_by":"Bruno Ughelli", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":140492},
{"id":87,"amount":"$439712.29","date":"17-04-2014","category":"Transportation","authorized_by":"Alex Portriss", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":102692},
{"id":88,"amount":"$300553.83","date":"15-11-2007","category":"Transportation","authorized_by":"Arlena Ridges", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":742016},
{"id":89,"amount":"$338539.18","date":"25-07-2009","category":"Welfare","authorized_by":"Sherri Kreber", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":692327},
{"id":90,"amount":"$635326.52","date":"11-01-2017","category":"Health","authorized_by":"Brit Balog", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":307397},
{"id":91,"amount":"$193749.24","date":"05-04-2007","category":"Electricity","authorized_by":"Lela Wisbey", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":548602},
{"id":92,"amount":"$873608.01","date":"14-07-2008","category":"Internet","authorized_by":"Janina Shortin", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":806595},
{"id":93,"amount":"$747257.13","date":"11-08-2019","category":"Stationary","authorized_by":"Barris O'Rudden", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":919804},
{"id":94,"amount":"$141392.64","date":"16-02-2021","category":"Stationary","authorized_by":"Astra Charrier", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":425104},
{"id":95,"amount":"$687560.26","date":"10-10-2021","category":"Health","authorized_by":"Fraze Nelle", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":394597},
{"id":96,"amount":"$118753.79","date":"04-08-2009","category":"Internet","authorized_by":"Darren Slipper", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":794230},
{"id":97,"amount":"$349108.12","date":"06-10-2014","category":"Transportation","authorized_by":"Stephannie Duthy", "expense_description": "Repair of Grinding and Shreding Equipment find me","product_id":779714},
{"id":98,"amount":"$388722.34","date":"18-03-2011","category":"Welfare","authorized_by":"Ernaline Kleszinski", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":159155},
{"id":99,"amount":"$467623.58","date":"10-09-2010","category":"Health","authorized_by":"Aili Gillice", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":545946},
{"id":100,"amount":"$790009.33","date":"16-07-2019","category":"Project","authorized_by":"Madalena Bidewel", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":243400},
{"id":101,"amount":"$377277.08","date":"30-06-2005","category":"Transportation","authorized_by":"Sapphire Leates", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":239785},
{"id":102,"amount":"$790712.85","date":"29-07-2007","category":"Transportation","authorized_by":"Kingston Leidl", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":272619},
{"id":103,"amount":"$934371.97","date":"11-07-2015","category":"Transportation","authorized_by":"Basile Bricket", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":778015},
{"id":104,"amount":"$609453.31","date":"28-04-2012","category":"Electricity","authorized_by":"Margarete Arnall", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":814714},
{"id":105,"amount":"$138343.05","date":"05-05-2015","category":"Flight","authorized_by":"Maureene Ginnell", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":175877},
{"id":106,"amount":"$570134.48","date":"19-11-2010","category":"Health","authorized_by":"Dell Weall", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":146428},
{"id":107,"amount":"$647921.51","date":"13-02-2012","category":"Project","authorized_by":"Cahra Burehill", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":851045},
{"id":108,"amount":"$999656.21","date":"30-11-2012","category":"Internet","authorized_by":"Leon Tiddy", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":573201},
{"id":109,"amount":"$432671.09","date":"04-06-2017","category":"Electricity","authorized_by":"Haley Deniskevich", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":607410},
{"id":110,"amount":"$995478.50","date":"12-09-2012","category":"Welfare","authorized_by":"Dani Mingaud", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":389419},
{"id":111,"amount":"$492080.19","date":"16-09-2005","category":"Project","authorized_by":"Jenine Patry", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":222947},
{"id":112,"amount":"$433293.09","date":"10-08-2009","category":"Stationary","authorized_by":"Gwen Lere", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":799996},
{"id":113,"amount":"$400134.19","date":"24-09-2013","category":"Flight","authorized_by":"Javier Dalgleish", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":995535},
{"id":114,"amount":"$737939.89","date":"08-06-2015","category":"Welfare","authorized_by":"Giovanni Pedlow", "expense_description": "Repair of Grinding and Shreding Equipment","product_id":872917}]




const category = [
    {
        id:1,
        category: "Transportation"
    },

    {
        id:2,
        category: "Flight",
    },

    {
        id:3,
        category:  "Project"
    },

    {
        id:4,
        category: "Electricity",
    },

    {
        id:5,
        category: "Welfare",
    },

   {
       id:6,
       category:  "Health",
   },

   {
       id:7,
       category:  "Stationary",
   },

   {
    id:8,
    category: "Internet",
}

   
   
    
  ];

export default {expenses, category}