const people = [
    {
        id: 1,
        name: "James Brown"
    },
    {
        id: 2,
        name: "James Green"
    },
    {
        id: 3,
        name: "James Blue"
    },
    {
        id: 4,
        name: "James Red"
    },
    {
        id: 5,
        name: "James Purple"
    },
    {
        id: 6,
        name: "James Coral"
    }
]
export default people
